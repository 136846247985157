module GlobalApplicationData {
    let app = angular.module("app");

    app.factory("GlobalApplicationData", ["$window", "$q", function ($window: Window, $q: any) {
        let baseUrl = "";
        let protocol = "";
        if ($window && $window.location) {
            // Calcolo il dominio di appartenenza per chiamare i servizi Rest
            let port = window.location.protocol === "http:" ? 80 : 443;
            protocol = window.location.protocol === "http:" ? "http" : "https";
            baseUrl = window.location.protocol + "//" + window.location.hostname + ":" + port + "/";
        }
        let GlobalApplicationData = {

            // HINT-ALLOY : Definisco il prefisso applicativo (context) e le altre varibili del server
            applicationContext: baseUrl,
            isAliveAddress: "../index.html",
            isMaintenanceAddress: "../isMaintenance.xml",

            // Indica la pagina originariamente richiesta dall'utente che ha scatenato l'autenticazione
            orginalRequestedURL: <string>null,

            // Variabile che contiene il token di autenticazione
            identityToken: <string>null,

            // Informazioni aggiuntive legate al token di autenticazione
            jwtPayload: <any>null,

            // Definisco l'array che conterrà tutte le possibili azioni a cui un utente è abilitato
            auths: <any>[],

            // Definisco l'array che conterrà le applicazioni abilitate all'utente
            applicationsEnabled: <any>[],

            // Definisco l'array per le lingue disponibili nell'applicazione che sarà popolato da authenticatingCtrl
            // L'attributo mandatory indica che è obbilgatoria e di default (infatti deve sempre essercene solo una di mandatory)
            langs: <any>[
                /*
                {lang: "en", label: "English (United States)", mandatory: true},
                {lang: "it", label: "Italiano (Italy)", mandatory: false}
                */
            ],

            // Millisecondi ogni quanto rinnovare il token utente (1 ora)
            tokenRenewalMillis: 3600000,
            // Id del timer associato al rinnovo del token
            tokenRenewalTimer: <any>null,

            // Millisecondi ogni quanto rinnovare il recupero degli indicatori (1 minuto)
            indicatorsRenewalMillis: 60000,
            // Id del timer associato al rinnovo degli indicatori
            indicatorsRenewalTimer: <any>null,

            // Tipo di device
            deviceType: <string>null,

            // UserAgent
            userAgent: <string>null
        };
        return GlobalApplicationData;
    }])
};